"use client";
import React, { FC } from "react";

import AnimatedLink from "./animated-link";
/**
 * Props for the Button component.
 */
export interface ButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  /**
   * The alignment of the button.
   */
  align?: "right" | "center" | "left" | "none";
  /**
   * Additional class name for the button.
   */
  className?: string;
  /**
   * The color variant of the button.
   */
  colorVariant?: "primary" | "secondary";
  /**
   * Specifies whether the button is disabled.
   */
  disabled?: boolean;
  /**
   * The URL to navigate to when the button is clicked (if the button is an anchor element).
   */
  href?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  internalHref?: any;
  /**
   * The name attribute of the button.
   */
  name?: string;
  /**
   * Callback function to be executed when the button is clicked.
   */
  onClick?: () => void;
  /**
   * The type of the button.
   */
  type?: "button" | "submit" | "reset";
  /**
   * The value attribute of the button.
   */
  value?: string;
  /**
   * The variant of the button.
   */
  variant?: "standard" | "small";
}

const sharedClasses =
  "more-contrast-border justify-center text-center transition-colors dark:hover:brightness-105 dark:focus-visible:brightness-105 hover:brightness-95 focus-visible:brightness-95 forced-colors:border-2 forced-colors:border-transparent";

export const Button: FC<ButtonProps> = ({
  align = "none",
  children,
  className,
  colorVariant = "primary",
  href,
  internalHref,
  onClick,
  variant = "standard",
  ...other
}) => {
  const buttonVariantStyles = {
    small:
      "px-5 py-3 text-lg lg:text-xl rounded-xl text-[clamp(16px,_1.125rem,_32px)]",
    standard:
      "min-w-[250px] px-5 py-6 text-lg lg:text-2xl rounded-xl text-[clamp(16px,_1.25rem,_32px)]",
  }[variant];

  const alignStyles = {
    right: "flex ml-auto mr-0",
    left: "flex ml-0 mr-auto",
    center: "flex m-[0 auto]",
    none: "inline-flex",
  }[align];

  const colorStyles = {
    primary: "bg-darkred text-white shadow-md ",
    secondary: "bg-red text-black dark:text-white dark:bg-slate-700",
  }[colorVariant];

  const linkClasses = `${className} ${sharedClasses} ${colorStyles} ${alignStyles} ${buttonVariantStyles} no-underline`;
  const buttonClasses = `${className} ${sharedClasses} ${colorStyles} ${alignStyles} ${buttonVariantStyles}`;

  const LinkComponent = AnimatedLink;

  return href || internalHref ? (
    <LinkComponent
      className={`${linkClasses}`}
      href={internalHref || href}
      {...other}
    >
      {children}
    </LinkComponent>
  ) : (
    <button
      className={`${buttonClasses}`}
      onClick={onClick}
      type="button"
      {...other}
    >
      {children}
    </button>
  );
};

export default Button;
