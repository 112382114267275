"use client";
import { FC } from "react";

import {
  MdCheck as Check,
  MdArrowForward as ArrowForward,
} from "react-icons/md";

import { Link } from "navigation";

export interface ChipProps {
  /**
   * The CSS class name for the chip.
   */
  className?: string;
  /**
   * The color of the chip.
   */
  color?: "green" | "yellow" | "red" | "gray" | "beige" | "blue" | "warning";
  /**
   * The type of component to render.
   */
  component?: "button" | "link" | "default";
  /**
   * The display style of the chip.
   */
  display?: "flex" | "inline-flex";
  /**
   * The font weight of the chip label.
   */
  fontWeight?: "normal" | "semibold";
  /**
   * Whether to force the background color of the chip.
   */
  forceBackground?: boolean;
  /**
   * The href for the link component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href?: any;
  /**
   * The label text of the chip.
   */
  label: string;
  /**
   * The click event handler.
   */
  onClick?: () => void;
  /**
   * Whether the chip is selected.
   */
  selected?: boolean;
  /**
   * The size of the chip.
   */
  size?: "small" | "large";
  /**
   * The inline style for the chip.
   */
  style?: React.CSSProperties;
}

export const Chip: FC<ChipProps> = ({
  className,
  onClick,
  href,
  size = "small",
  display = "flex",
  color = "gray",
  label,
  component = "default",
  selected = component === "button" ? false : undefined,
  fontWeight = "semibold",
  forceBackground = false,
  style,
}) => {
  const commonStyles = `${
    forceBackground ? "" : "dark:bg-transparent"
  } border-2 border-transparent`;
  const colorStyles = {
    green: `${commonStyles} dark:border-green bg-green`,
    yellow: `${commonStyles} dark:border-yellow bg-yellow`,
    red: `${commonStyles} dark:border-red bg-red`,
    gray: `${commonStyles} dark:border-gray-400 bg-gray-200`,
    beige: `${commonStyles} dark:border-beige bg-beige`,
    blue: `${commonStyles} dark:border-lightblue bg-lightblue`,
    warning: `${commonStyles} dark:border-warning bg-warning`,
  }[color];

  const sizeStyles = {
    small: "py-[2px] px-[12px] text-sm ",
    large: "py-[2px] px-[16px] text-base dark:py-[4px] ",
  }[size];

  const displayStyles = {
    flex: "flex",
    "inline-flex": "inline-flex",
  }[display];

  const buttonStyles =
    "outline-4 outline-darkblue dark:outline-white hover:brightness-90 no-underline";

  const fontWeightStyles = {
    normal: "font-normal",
    semibold: "font-semibold",
  }[fontWeight];

  const sharedProps = {
    className: `${color ? colorStyles : ""} ${sizeStyles} ${fontWeightStyles} ${
      component === "button" || component === "link" ? buttonStyles : ""
    } ${displayStyles} w-fit rounded-[1rem] text-black ${
      forceBackground ? "" : "dark:text-white"
    } ${className}`,
    style,
  };

  const chipLink =
    component === "link" && href ? (
      <Link href={href} {...sharedProps}>
        {label}
        <ArrowForward className="ml-1 shrink-0 self-center text-base opacity-70 group-focus-within:opacity-100 group-hover:opacity-100" />
      </Link>
    ) : null;

  const chipButton =
    component === "button" && onClick ? (
      <button
        aria-pressed={component === "button" ? selected : undefined}
        onClick={onClick}
        type="button"
        {...sharedProps}
      >
        {selected && <Check className="mr-2 inline self-center text-base" />}
        {label}
      </button>
    ) : null;

  const chipSpan =
    component === "default" ? <span {...sharedProps}>{label}</span> : null;

  const renderChipVariant = {
    button: chipButton,
    link: chipLink,
    default: chipSpan,
  }[component];

  return renderChipVariant;
};
export default Chip;
