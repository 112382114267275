import { ElementType, FC } from "react";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "p"
  | "span"
  | "srOnly"
  | "preamble"
  | "button"
  | "link";

interface SharedProps {
  /**
   * The content to be rendered inside the typography component.
   */
  children?: React.ReactNode;

  /**
   * The CSS class name for the typography component.
   */
  className?: string;

  /**
   * The color variant of the typography.
   * @default 'primary'
   */
  colorVariant?: "primary" | "secondary";

  /**
   * Determines if the typography component should be responsive.
   */
  responsive?: boolean;
}

interface BaseProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    React.HTMLAttributes<HTMLHeadingElement> {
  /** The HTML component to use.  */
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  /** The typography variant */
  variant?: Exclude<Variant, "link">;
}

interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  /** The component type to render */
  component?: "a" | "span" | "p";
  /** The URL to link to */
  href?: string;
  /** The typography variant */
  variant: "link";
}

export type TypographyProps =
  | (SharedProps & BaseProps)
  | (SharedProps & LinkProps);

const mapVariantToComponent = (
  variant: TypographyProps["variant"],
): ElementType => {
  switch (variant) {
    case "h1":
      return "h1";
    case "h2":
      return "h2";
    case "h3":
      return "h3";
    case "h4":
      return "h4";
    case "p":
      return "p";
    case "span":
      return "span";
    case "srOnly":
      return "span";
    case "preamble":
      return "p";
    case "button":
      return "button";
    case "link":
      return "a";
    default:
      return "p";
  }
};

export const Typography: FC<TypographyProps> = ({
  children,
  className = "",
  variant = "p",
  colorVariant = "primary",
  component = mapVariantToComponent(variant),
  responsive = true,
  ...other
}) => {
  const sharedClasses = "break-words overflow-ellipsis";
  const variantClasses = {
    h1: `sm:text-4xl text-[clamp(36px,_2.25rem,_48px)] font-semibold leading-tight lg:leading-tight ${
      responsive ? "lg:text-6xl" : ""
    }`,
    h2: `sm:text-3xl text-[clamp(30px,_1.875rem,_48px)] font-semibold leading-tight ${
      responsive ? "lg:text-4xl" : ""
    }`,
    h3: `text-xl font-semibold ${responsive ? "lg:text-2xl" : ""}`,
    h4: `text-xl font-semibold`,
    p: `text-lg ${responsive ? "lg:text-xl" : ""}`,
    span: "",
    srOnly: "sr-only",
    preamble: `text-xl leading-10 ${
      responsive ? "lg:text-2xl lg:leading-10" : ""
    }`,
    button: `text-lg ${responsive ? "lg:text-xl" : ""}`,
    link: "text-link",
  }[variant];

  const colorClasses = {
    primary: "",
    secondary: "opacity-70 more-contrast:opacity-100",
  }[colorVariant];

  const Component = component;
  return (
    <Component
      className={`${sharedClasses} ${colorClasses} ${variantClasses} ${className}`}
      style={{
        ...(variant === "preamble" ? { lineHeight: 1.75 } : {}),
        ...(other.style || {}),
      }}
      {...other}
    >
      {children}
    </Component>
  );
};
export default Typography;
